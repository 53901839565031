import { BibleIndicatorActionType, BibleIndicatorContext, BibleIndicatorDispatchContext } from "../state/BibleIndicator.context";
import { useContext, useEffect, useRef, useState } from "react";
import { Verse, Word, db, isVerse } from "../BibleDatabase/BibleDB";
import { useLiveQuery } from "dexie-react-hooks";
import BibleWordComponent from "./BibleWord.component";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";


function BibleReaderComponent() {
  const readerRef = useRef<HTMLDivElement>(null);
  const stateBible = useContext(BibleIndicatorContext);
  const dispatchBible = useContext(BibleIndicatorDispatchContext);
  const [queryId, setQueryId] = useState<number>(0); // State variable for key prop
  const [words, setWords] = useState<(Word|{number:number})[]|undefined>(undefined);

  useLiveQuery(() =>
    db.bible
      .where({ bookNumber: stateBible?.book, chapterNumber: stateBible?.chapter })
      .sortBy("id")
      .then(prepWords),
      [queryId]
  );

  // if bibleState updates we need to requery, change the id to force a requery.
  useEffect(() => {
    setQueryId(id => id + 1);
  }, [stateBible]);

  useGSAP(
    () => {
      setTimeout(() => {
        if (stateBible?.highlight) {       
          const verseElement = document.querySelector(`#v${stateBible?.verse}`);
          verseElement?.scrollIntoView({behavior: "smooth", block: "center"});

          //const verseElements = readerRef.current?.querySelectorAll(`.v${stateBible?.verse}`);
          //verseElements.forEach(el => el.classList.add("highlight"));
          gsap.from(`.v${stateBible?.verse}`, { duration: 2.5, color: "#AA00FF", stagger: 0.03 });
          dispatchBible?.({type: BibleIndicatorActionType.REMOVE_HIGHLIGHT});
        }
      }, 10);
    },
    {dependencies: [words], scope: readerRef}
  );

  // Function to group items by verseNumber
  const prepWords = (items: Word[]): void => {
    let priorVerse = 0;
    const words = [];
    for (let item of items) {
      if (priorVerse !== item.verseNumber)
        words.push({ number: item.verseNumber });
      words.push(item);
      priorVerse = item.verseNumber;
    }
    setWords(words);
  };

  const renderWord = (word: Word | Verse, index:number) => {
    if (isVerse(word))
      return <sup id={`v${word.number}`} className={`v${word.number}`} key={"verse" + word.number}>{word.number}</sup>;
    else return <BibleWordComponent key={`${index}`} word={word}/>;
  };

  return (
    <div className="reader" key="wrapper" ref={readerRef}>
      {words?.map((word, index) => renderWord(word, index))}
    </div>
  );
}

export default BibleReaderComponent;
