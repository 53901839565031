// BibleDB.ts
import Dexie from 'dexie';

export interface Word {
    id?: any,
    bookNumber: number;
    chapterNumber: number;
    verseNumber: number;
    hebrewWord: string;
    strongsNumber: string;
    morphology: string;
    transliteration: string;
    translation: string;
};

export interface BDBDefinition {
    id?: any;
    strongNumber: string;
    hebrew: string;
    definition: string;
    secondary: string[];
};

export interface StrongDefinition {
    id?: any;
    strongNumber: string;
    lemma: string;
    transliteration: string;
    pronounciation: string;
    derivation: string;
    strongs_def: string;
    kjv_def: string; 
}

export interface Verse {
    number: number;
}

export function isVerse(obj: any): obj is Verse {
    return (
      obj &&
      typeof obj.number === 'number'
    );
}

export function isWord(obj:any): obj is Word {
    return (
        obj &&
        typeof obj.bookNumber === 'number' &&
        typeof obj.hebrewWord === 'string' &&
        typeof obj.chapterNumber === 'number'
    )
}

class BibleDB extends Dexie {
    bible!: Dexie.Table<Word, number>;
    bdb!: Dexie.Table<BDBDefinition, number>;
    strongs!: Dexie.Table<StrongDefinition, number>;

    constructor() {
        super("bibleDatabase");
        this.version(2).stores({
            bible: `++id, 
            bookNumber, 
            chapterNumber,
            verseNumber, 
            hebrewWord, 
            strongsNumber, 
            morphology, 
            transliteration, 
            translation,
            [bookNumber+chapterNumber],
            [bookNumber+chapterNumber+verseNumber]
            `,
            strongs: `++id,
            strongNumber,
            lemma,
            transliteration,
            pronounciation,
            derivation,
            definition,
            kjv_definition
            `,
            bdb: `++id,
            strongNumber,
            hebrew,
            definition,
            secondary
            `
        });
        //this.bible = this.table("bible");
    }

}

export const db = new BibleDB();
