export enum MorphPartOfSpeechType {
  Verb = "V",
  Noun = "N",
  Preposition = "Prep",
  Conjunction = "Conj",
  Number = "Number",
  Article = "Art",
  Adjective = "Adj",
  Pronoun = "Pro",
  DirectObjectMarker = "DirObjM",
  Adverb = "Adv",
  Interjection = "I",
  Interrogative = "Interrog",
}

export enum MorphPrepType {
  B = "b",
  K = "k",
  L = "l",
  M = "m",
}

export enum MorphConjType {
  W = "w",
}

export enum MorphAdvType {
  NegativeParticle = "NegPrt",
}

export enum MorphAspectBaseType {
  Perfect = "Perf",
  ConjunctivePerfect = "ConjPerf",
  Imperative = "Imp",
  Participle = "Prtcpl",
  QalPassParticiple = "QalPassPrtcpl",
  InfinitiveConstruct = "Inf",
  InfinitiveAbsolute = "InfAbs",
}

export enum MorphAspectImperfType {
  ConjunctiveImperfect = "ConjImperf",
  ConsecutiveImperfect = "ConsecImperf",
  Imperfect = "Imperf",
}

export enum MorphAspectImperfAddType {
  None = "",
  Cohortative = ".Cohort",
  CohortativeForce = ".h",
  Jussive = ".Jus",
}

export enum MorphVerbStemType {
  Hiphil = "Hifil",
  Hophal = "Hophal",
  Pual = "Pual",
  Piel = "Piel",
  Hithpael = "Hitpael",
  Qal = "Qal",
  Nifal = "Nifal",
  Qalpass = "Qalpass",
}

export enum MorphPronounType {
  Relative = "r",
}

export enum MorphNounType {
  Proper = "proper",
  Ordinal = "o",
  Gentilic = "g",
}

export enum MorphNounStateType {
  Construct = "c",
  Determinate = "d",
}

export enum MorphAdjStateType {
    Construct = "c",
    Determinate = "d",
  }

export enum MorphPersonType {
  FirstPerson = "1",
  SecondPerson = "2",
  ThirdPerson = "3",
  None = "",
}

export enum MorphGenderType {
  Masculine = "m",
  Feminine = "f",
  Common = "c",
}

export enum MorphNumberType {
  Singular = "s",
  Plural = "p",
  Dual = "d",
}

export interface Morphology {
  partOfSpeech: MorphPartOfSpeechType;
}

export interface VerbMorphology extends Morphology {
  stem: MorphVerbStemType;
  aspect: MorphAspectBaseType | MorphAspectImperfType;
  irregular: MorphAspectImperfAddType;
  person: MorphPersonType;
  gender: MorphGenderType;
  number: MorphNumberType;
}

export interface PronounMorphology extends Morphology {
  type: MorphPronounType;
  person: MorphPersonType;
  gender: MorphGenderType;
  number: MorphNumberType;
}

export interface NounMorphology extends Morphology {
  type: MorphNounType;
  gender: MorphGenderType;
  number: MorphNounType;
  state: MorphNounStateType;
}

export interface NumberMorphology extends Morphology {
  type: MorphNounType;
  gender: MorphGenderType;
  number: MorphNounType;
  state: MorphNounStateType;
}

export interface AdjectiveMorphology extends Morphology {
  gender: MorphGenderType;
  number: MorphNounType;
  state: MorphNounStateType;
}

export interface PrepMorphology extends Morphology {
  type: MorphPrepType;
}

export interface ConjMorphology extends Morphology {
  type: MorphConjType;
}

export interface AdverbMorphology extends Morphology {
  type: MorphAdvType;
}

export function parseMorphology(morphology: string, hebrewWord: string): Morphology[] {
  const parsedMorphology: Morphology[] = [];
  const parts = morphology.split("│");
  for (let part of parts) {
    const split = part.split(",");
    for (let s of split) {
      parsedMorphology.push(parseWord(s, hebrewWord) as Morphology);
    }
  }
  return parsedMorphology;
}

export function morphologyAsString(morphology: any) {
  return [
    morphology.type,
    morphology.stem,
    morphology.aspect,
    morphology.irregular,
    morphology.person,
    morphology.gender,
    morphology.number,
  ].join(" ");
}

function parseWord(morphology: string, word: string) {
  //VERB
  const regexVerb: RegExp =
    /^V-([^-]*)-([^-.]*)(?:\.([^-]*))?-([123])?([mfc])?([sp])?/im;
  const matchVerb = regexVerb.exec(morphology);
  if (matchVerb && matchVerb.length) {
    return {
      partOfSpeech: "Verb",
      stem: ReverseEnum(MorphVerbStemType ,matchVerb[1]),
      aspect: ReverseEnum(MorphAspectBaseType, matchVerb[2]) || ReverseEnum(MorphAspectImperfType, matchVerb[2]),
      irregular: matchVerb[3],
      person: ReverseEnum(MorphPersonType, matchVerb[4]),
      gender: ReverseEnum(MorphGenderType, matchVerb[5]),
      number: ReverseEnum(MorphNumberType, matchVerb[6]),
    };
  }

  //ARTICLE
  const regexArticle: RegExp = /^Art/im;
  const matchArticle = regexArticle.exec(morphology);
  if (matchArticle) {
    return {
      partOfSpeech: "Article",
    };
  }

  //CONJUNCTION
  const regexConj: RegExp = /^Conj-?(\w)?/im;
  const matchConj = regexConj.exec(morphology);
  if (matchConj && matchConj.length) {
    return {
      partOfSpeech: "Conjunction",
      type: ReverseEnum(MorphConjType, matchConj[1]),
    };
  }

  //PREPOSITION
  const regexPrep: RegExp = /^Prep-?(\w)?/im;
  const matchPrep = regexPrep.exec(morphology);
  if (matchPrep && matchPrep.length) {
    return {
      partOfSpeech: "Preposition",
      type: ReverseEnum(MorphPrepType, matchPrep[1]),
    };
  }

  //DIRECT OBJECT MARKER
  const regexDirObj: RegExp = /^DirObjM/im;
  const matchDirObj = regexDirObj.exec(morphology);
  if (matchDirObj) {
    return {
      partOfSpeech: "Direct Object Marker",
    };
  }

  //INTERROGATIVE
  const regexInter: RegExp = /^Interrog/im;
  const matchInter = regexInter.exec(morphology);
  if (matchInter) {
    return {
      partOfSpeech: "Interrogative",
    };
  }

  //INTERJECTION
  const regexI: RegExp = /^I$/im;
  const matchI = regexI.exec(morphology);
  if (matchI) {
    return {
      partOfSpeech: "Interjection",
    };
  }

  //ADVERB
  const regexAdv: RegExp = /^Adv-?(\w)?$/im;
  const matchAdv = regexAdv.exec(morphology);
  if (matchAdv) {
    return {
      partOfSpeech: "Adverb",
      type: ReverseEnum(MorphAdvType, matchAdv[1]),
    };
  }

  //NOUN
  const regexNoun: RegExp = /^N-(?:(proper|g)-)?([mfc])?([spd])?([cd])?/im;
  const matchNoun = regexNoun.exec(morphology);
  if (matchNoun && matchNoun.length) {
    return {
      partOfSpeech: "Noun",
      type: ReverseEnum(MorphNounType, matchNoun[1]),
      gender: ReverseEnum(MorphGenderType, matchNoun[2]),
      number: ReverseEnum(MorphNumberType, matchNoun[3]),
      state: ReverseEnum(MorphNounStateType, matchNoun[4]),
    };
  }

  //ADJECTIVE
  const regexAdj: RegExp = /^Adj-([mfc])?([spd])?([cd])?/im;
  const matchAdj = regexAdj.exec(morphology);
  if (matchAdj && matchAdj.length) {
    return {
      partOfSpeech: "Adjective",
      gender: ReverseEnum(MorphGenderType, matchAdj[1]),
      number: ReverseEnum(MorphNumberType, matchAdj[2]),
      state: ReverseEnum(MorphAdjStateType, matchAdj[3]),
    };
  }

  //NUMBER
  const regexNum: RegExp = /^Number-(?:(o)-)?([mfc])?([sdp])?([cd])?/im;
  const matchNum = regexNum.exec(morphology);
  if (matchNum && matchNum.length) {
    return {
      partOfSpeech: "Number",
      type: matchNum[1],
      gender: ReverseEnum(MorphGenderType, matchNum[2]),
      number: matchNum[3],
      state: matchNum[4],
    };
  }

  //PRONOUN
  const regexPro: RegExp = /^Pro-(?:(r)-?)?([123])?([mfc])?([sdp])?/im;
  const matchPro = regexPro.exec(morphology);
  if (matchPro && matchPro.length) {
    return {
      partOfSpeech: "Pronoun",
      type: ReverseEnum(MorphPronounType, matchPro[1]),
      person: ReverseEnum(MorphPersonType, matchPro[2]),
      gender: ReverseEnum(MorphGenderType, matchPro[3]),
      number: ReverseEnum(MorphNumberType, matchPro[4]),
    };
  }

  //POSESSIVE
  const regexPos: RegExp = /^([123])?([mfc])?([sdp])?/im;
  const matchPos = regexPos.exec(morphology);
  if (matchPos && matchPos.length) {
    return {
      partOfSpeech: "",
      person: ReverseEnum(MorphPersonType, matchPos[1]),
      gender: ReverseEnum(MorphGenderType, matchPos[2]),
      number: ReverseEnum(MorphNumberType, matchPos[3]),
    };
  }
}

function ReverseEnum<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
}