// BibleTypes.ts
export type WordData = [
    number, // book number
    number, // chapter number
    number, // verse number
    string, // hebrew word
    string, // strong's number
    string, // morphology
    string, // transliteration
    string  // translation
];

export type Verse = WordData[];

export const Books: { [key: number]: { name: string; hebrew: string } } = {
    1: { name: "Genesis", hebrew: "בְּרֵאשִׁ֖ית" },
    2: { name: "Exodus", hebrew: "שְׁמוֹת" },
    3: { name: "Leviticus", hebrew: "וַיִּקְרָא" },
    4: { name: "Numbers", hebrew: "בְּמִדְבַּר" },
    5: { name: "Deuteronomy", hebrew: "דְּבָרִים" },
    6: { name: "Joshua", hebrew: "יְהוֹשֻׁעַ" },
    7: { name: "Judges", hebrew: "שׁוֹפְטִים" },
    8: { name: "Ruth", hebrew: "רוּת" },
    9: { name: "1 Samuel", hebrew: "שְׁמוּאֵל א" },
    10: { name: "2 Samuel", hebrew: "שְׁמוּאֵל ב" },
    11: { name: "1 Kings", hebrew: "מְלָכִים א" },
    12: { name: "2 Kings", hebrew: "מְלָכִים ב" },
    13: { name: "1 Chronicles", hebrew: "דִּבְרֵי הַיָּמִים א" },
    14: { name: "2 Chronicles", hebrew: "דִּבְרֵי הַיָּמִים ב" },
    15: { name: "Ezra", hebrew: "עֶזְרָא" },
    16: { name: "Nehemiah", hebrew: "נְחֶמְיָה" },
    17: { name: "Esther", hebrew: "אֶסְתֵר" },
    18: { name: "Job", hebrew: "אִיּוֹב" },
    19: { name: "Psalms", hebrew: "תְהִלִּים" },
    20: { name: "Proverbs", hebrew: "מִשְׁלֵי" },
    21: { name: "Ecclesiastes", hebrew: "קֹהֶלֶת" },
    22: { name: "Song of Solomon", hebrew: "שִׁיר הַשִּׁירִים" },
    23: { name: "Isaiah", hebrew: "יְשַׁעְיָהוּ" },
    24: { name: "Jeremiah", hebrew: "יִרְמְיָהוּ" },
    25: { name: "Lamentations", hebrew: "אֵיכָה" },
    26: { name: "Ezekiel", hebrew: "יְחֶזְקֵאל" },
    27: { name: "Daniel", hebrew: "דָּנִיֵּאל" },
    28: { name: "Hosea", hebrew: "הוֹשֵׁעַ" },
    29: { name: "Joel", hebrew: "יוֹאֵל" },
    30: { name: "Amos", hebrew: "עָמוֹס" },
    31: { name: "Obadiah", hebrew: "עוֹבַדְיָה" },
    32: { name: "Jonah", hebrew: "יוֹנָה" },
    33: { name: "Micah", hebrew: "מִיכָה" },
    34: { name: "Nahum", hebrew: "נַחוּם" },
    35: { name: "Habakkuk", hebrew: "חֲבַקּוּק" },
    36: { name: "Zephaniah", hebrew: "צְפַנְיָה" },
    37: { name: "Haggai", hebrew: "חַגַּי" },
    38: { name: "Zechariah", hebrew: "זְכַרְיָה" },
    39: { name: "Malachi", hebrew: "מַלְאָכִי" },
  };