import { Button, ButtonGroup } from "@nextui-org/button";
import {
  BibleIndicatorActionType,
  BibleIndicatorContext,
  BibleIndicatorDispatchContext,
} from "../state/BibleIndicator.context";
import { useContext, useEffect, useRef, useState } from "react";
import { Books } from "../BibleDatabase/BibleTypes";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/modal";
import { db } from "../BibleDatabase/BibleDB";
import { Navbar } from "@nextui-org/navbar";

function BibleSelectorComponent() {
  const navRef = useRef<HTMLDivElement>(null);
  const stateBible = useContext(BibleIndicatorContext);
  const dispatchBible = useContext(BibleIndicatorDispatchContext);
  const [totalChapters, setTotalChapters] = useState<number>(0);
  const [totalVerses, setTotalVerses] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [tempVerse, setTempVerse] = useState<{book?:number, chapter?:number, verse?:number}>({book: 0, chapter: 0, verse: 0});

  const BookNums: string[] = Object.keys(Books);
  const {
    isOpen: isOpenBook,
    onOpen: onOpenBook,
    onClose: onCloseBook,
  } = useDisclosure();

  const {
    isOpen: isOpenChapter,
    onOpen: onOpenChapter,
    onClose: onCloseChapter,
  } = useDisclosure();

  const {
    isOpen: isOpenVerse,
    onOpen: onOpenVerse,
    onClose: onCloseVerse,
  } = useDisclosure();

  useEffect(() => {
    const setInitial = async () => {
        doSetTotalChapters();
        doSetTotalVerses();
    };
    setInitial();
  });

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const nav: HTMLDivElement|null = navRef.current;
      if (currentScrollPos > scrollPosition) {
        //down
        nav?.classList.add("hide");
      } else if (currentScrollPos < scrollPosition) {
        //up
        nav?.classList.remove("hide");
      }

      setScrollPosition(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  if (!stateBible || !dispatchBible) return null;

  const doBookSelect = async (book: number | string) => {
    if (typeof book === "string") book = parseInt(book);
    //dispatchBible({type: BibleIndicatorActionType.SET_BOOK, value: book});
    setTempVerse({...tempVerse, book});
    onCloseBook();

    await doSetTotalChapters(book);
    onOpenChapter();
  }

  const doChapterSelect = async (chapter:number) => {
    //dispatchBible({type: BibleIndicatorActionType.SET_CHAPTER, value: chapter});
    setTempVerse({...tempVerse, chapter});
    onCloseChapter();

    await doSetTotalVerses(chapter);
    onOpenVerse();
  }

  const doVerseSelect = async (verse:number) => {
    setTempVerse({...tempVerse, verse});
    dispatchBible({
      type: BibleIndicatorActionType.SET_FULL_VERSE, 
      book: tempVerse.book || stateBible.book, 
      chapter: tempVerse.chapter || stateBible.chapter, 
      verse: verse || stateBible.verse,
    });
    onCloseVerse();
  }

  const doSetTotalChapters = async (book?: number) => {
    if (!book) book = tempVerse.book;
    const lastWord = await db.bible.where({bookNumber: book}).limit(1).reverse().sortBy("id");
    const chapters = lastWord[0].chapterNumber;
    setTotalChapters(chapters);
    return chapters;
  }

  const doSetTotalVerses = async (chapter?: number) => {
    if (!chapter) chapter = tempVerse.chapter;
    const lastWord = await db.bible.where({bookNumber: tempVerse.book, chapterNumber: chapter}).limit(1).reverse().sortBy("id");
    if (!lastWord || !lastWord.length) return;
    setTotalVerses(lastWord[0].verseNumber);
  }

  const onPrevChapter = async () => {
    const newChapter = stateBible.chapter - 1;
    if (newChapter > 0) {
        dispatchBible({type: BibleIndicatorActionType.PREV_CHAPTER});
    } else if (stateBible.book > 1) {
        const newBook = stateBible.book - 1;
        //dispatchBible({type: BibleIndicatorActionType.SET_BOOK, value: newBook});
        const chapters = await doSetTotalChapters(newBook);
        //dispatchBible({type: BibleIndicatorActionType.SET_CHAPTER, value: chapters});
        dispatchBible({type: BibleIndicatorActionType.SET_FULL_VERSE, book: newBook, chapter: chapters, verse: 1, highlight: false});
    }
  };

  const onNextChapter = async () => {
    const newChapter = stateBible.chapter + 1;
    if (newChapter <= totalChapters) {
        dispatchBible({type: BibleIndicatorActionType.NEXT_CHAPTER});
    } else if (stateBible.book < 39) {
        const newBook = stateBible.book + 1;
        //dispatchBible({type: BibleIndicatorActionType.SET_BOOK, value: newBook});
        await doSetTotalChapters(newBook);
        //dispatchBible({type: BibleIndicatorActionType.SET_CHAPTER, value: 1});
        dispatchBible({type: BibleIndicatorActionType.SET_FULL_VERSE, book: newBook, chapter: 1, verse: 1, highlight: false});
    }
  };

  const chapterArray = () => {
    const chapters = [];
    for (let i = 1; i <= totalChapters; i++) {
        chapters.push(i);
    }
    return chapters;
  }

  const verseArray = () => {
    const verses = [];
    for (let i = 1; i <= totalVerses; i++) {
        verses.push(i);
    }
    return verses;
  }

  return (
    <div id="nav-wrapper" ref={navRef}>
      <Navbar>
      <nav className="flex flex-wrap items-center justify-center gap-x-2 py-1">
        <ButtonGroup size="sm" color="secondary">
          <Button onClick={onOpenBook}>{Books[stateBible.book].hebrew}</Button>
          <Button onClick={onOpenChapter}>{stateBible.chapter}</Button>
          <Button onClick={onOpenVerse}>{stateBible.verse}</Button>
        </ButtonGroup>
        <ButtonGroup size="sm" variant="flat" color="secondary">
          <Button onClick={onPrevChapter}>
            &lang;
          </Button>
          <Button onClick={onNextChapter}>
            &rang;
          </Button>
        </ButtonGroup>
      </nav>
      </Navbar>

      <Modal
        className="dark text-foreground bg-background"
        size="full"
        isOpen={isOpenBook}
        onClose={onCloseBook}
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Choose Book
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-wrap-reverse">
                  <div className="grid grid-cols-3 gap-1 w-full">
                    { BookNums.map(bookStr => (
                        <div key={bookStr} className="w-full">
                            <Button fullWidth={true} color="secondary" onClick={() => doBookSelect(bookStr)}>
                                {Books[parseInt(bookStr)].hebrew}
                            </Button>
                        </div>
                    ))}
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        className="dark text-foreground bg-background"
        size="full"
        isOpen={isOpenChapter}
        onClose={onCloseChapter}
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Choose Chapter
              </ModalHeader>
              <ModalBody>
                <div className="modalscroller">
                <div className="flex flex-wrap-reverse">
                  <div className="grid grid-cols-4 gap-1 w-full">
                    { chapterArray().map(chapter => (
                        <div key={chapter} className="w-full">
                            <Button fullWidth={true} color="secondary" onClick={() => doChapterSelect(chapter)}>
                                {chapter}
                            </Button>                            
                        </div>
                    ))}
                  </div>
                </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        className="dark text-foreground bg-background"
        size="full"
        isOpen={isOpenVerse}
        onClose={onCloseVerse}
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Choose Verse
              </ModalHeader>
              <ModalBody>
                <div className="modalscroller">
                <div className="flex flex-wrap-reverse">
                  <div className="grid grid-cols-4 gap-1 w-full">
                    { verseArray().map(verse => (
                        <div key={verse} className="w-full">
                            <Button fullWidth={true} color="secondary" onClick={() => doVerseSelect(verse)}>
                                {verse}
                            </Button>                            
                        </div>
                    ))}
                  </div>
                </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}

export default BibleSelectorComponent;
