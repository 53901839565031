import {
    Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/modal";

import {
  BibleIndicatorActionType,
  BibleIndicatorContext,
  BibleIndicatorDispatchContext,
} from "../state/BibleIndicator.context";
import { useContext, useEffect, useRef, useState } from "react";
import { Morphology, morphologyAsString, parseMorphology } from "../utilities/MorphologyParser";
import { useLiveQuery } from "dexie-react-hooks";
import { Word, db } from "../BibleDatabase/BibleDB";
import { Tab, Tabs } from "@nextui-org/tabs";
import { Chip } from "@nextui-org/chip";
import { Books } from "../BibleDatabase/BibleTypes";
import { Divider } from "@nextui-org/divider";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import { Button } from "@nextui-org/button";

const BibleWordStudyComponent = () => {
  const stateBible = useContext(BibleIndicatorContext);
  const dispatchBible = useContext(BibleIndicatorDispatchContext);

  const [queryId, setQueryId] = useState<number>(0); // State variable for key prop
  const [tabs, setTabs] = useState<any[]>([]);
  const [verses, setVerses] = useState<any[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const morphology = useRef<Morphology[]>([]);
  
  let queryTabs:any[] = [];

  useLiveQuery(() => {
    const strongs = stateBible?.wordStudy?.strongsNumber;
    if (!strongs) return;

    queryTabs = [];

    db.strongs
      .where({ id: strongs })
      .sortBy("id")
      .then(strongsDetails);
    db.bdb
      .where({ id: strongs })
      .sortBy("id")
      .then(bdbDetails);
    
    getEntries(strongs).then(res => setVerses(res));
  }, [queryId]);

  useEffect(() => {
    setQueryId(id => id + 1);
    if (stateBible?.wordStudy) {
        morphology.current = parseMorphology(stateBible.wordStudy.morphology, stateBible.wordStudy.hebrewWord);
        onOpen();
    }
    else onClose();
  }, [stateBible?.wordStudy, onOpen, onClose]);

  if (!stateBible || !dispatchBible) return null;

// Function to find all entries with a specific strongsNumber
  const findEntriesByStrongsNumber = async (strongsNumber: string) => {
    const results = await db.bible.where({ strongsNumber }).toArray();
    return results;
  }
  
  // Function to find all entries with the same bookNumber+chapterNumber+verseNumber, ordered by id
  const findEntriesByBookChapterVerse = async (bookNumber:number, chapterNumber:number, verseNumber:number) => {
    const results = await db.bible
      .where('[bookNumber+chapterNumber+verseNumber]')
      .equals([bookNumber, chapterNumber, verseNumber])
      .sortBy('id');
    return results;
  }

  const getEntries = async (strongsNumber: string) => {
    const entriesByStrongsNumber = await findEntriesByStrongsNumber(strongsNumber);
  
    const allResults = await Promise.all(entriesByStrongsNumber.map(async (entry) => {
      const { bookNumber, chapterNumber, verseNumber } = entry;
      const relatedEntries = await findEntriesByBookChapterVerse(bookNumber, chapterNumber, verseNumber);
      return {
        entry,
        verse: relatedEntries
      };
    }));
  
    return allResults;
  }

  const strongsDetails = (details: any) => {
    console.log(details);
    if (!details || !details.length) return;
    queryTabs = [
      ...queryTabs,
      {id: "strongs", label: "Strongs", content: details}
    ];
    setTabs(queryTabs);
  };

  const bdbDetails = (details: any) => {
    console.log(details)
    if (!details || !details.length) return;
    queryTabs = [
      ...queryTabs,
      {id: "bdb", label: "BDB", content: details}
    ];
    setTabs(queryTabs);
  };

  const getLemma = (item:any) => {
    if (item.content?.length) {
      return item.content[0].lemma || item.content[0].hebrew
    }
  };

  const getStrongNum = (item:any) => {
    if (item.content?.length) {
      return item.content[0].id;
    }
  };

  const renderTab = (item:any) => {
    const {id, content} = item;
    if (id === "bdb") {
      return (
        <>
          <ScrollShadow className="h-[160px]" hideScrollBar>
          {content.map((item:any, index:number) => (
          <div key={`bdb${index}`}>
            <h2 className="defs">{item.definition}</h2>
            <ul className="defs">
              {item.secondary.map((secondary:string, i:number) => (
                <li key={i} dangerouslySetInnerHTML={{ __html: secondary }}></li>
              ))}
            </ul>
          </div>
          ))}
          </ScrollShadow>
        </>
      )
    }
    else if (id === "strongs") {
      return (
        <>
          <ScrollShadow className="h-[160px]" hideScrollBar>
          {content.map((item:any, index:number) => (
          <div key={`strongs${index}`}>
            <ul className="defs">
              <li key="def">{item.definition}</li>
              <li key="kjvdef">{item.kjv_definition}</li>
              <li key="deriv">{item.derivation}</li>
            </ul>
          </div>
          ))}
          </ScrollShadow>
        </>
      )
    }
  }

  const openWordStudy = (word: any) => {
    console.log(word, stateBible?.wordStudy);
    if (stateBible?.wordStudy) {
      dispatchBible({type: BibleIndicatorActionType.SET_WORDSTUDY, word: stateBible.wordStudy});
    }
  };

  const gotoVerse = (book:number, chapter:number, verse:number) => {
    dispatchBible({type: BibleIndicatorActionType.UNSET_WORD });
    dispatchBible({type: BibleIndicatorActionType.UNSET_WORDSTUDY });
    dispatchBible({type: BibleIndicatorActionType.SET_FULL_VERSE, book, chapter, verse});
  };

  const bookNumber = stateBible?.wordStudy?.bookNumber || -1;
  const book = Books[bookNumber]?.name;
  const chapter = stateBible?.wordStudy?.chapterNumber;
  const verse = stateBible?.wordStudy?.verseNumber;

  return (
    <Modal
      className="dark text-foreground bg-background"
      placement="bottom"
      backdrop="blur"
      size="full"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        dispatchBible?.({type: BibleIndicatorActionType.UNSET_WORD});
        dispatchBible?.({type: BibleIndicatorActionType.UNSET_WORDSTUDY});
      }}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {book && (<div className="flex">
                <Chip
                  variant="flat"
                  color="secondary"
                >
                  {book} {chapter}:{verse}
                </Chip>
              </div>)}

              <h1 className="heb-word">{stateBible?.wordStudy?.hebrewWord}</h1>

            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col wordstudy">
                {morphology?.current?.map((morph: Morphology, index: number) => {
                  return (<div className="chips" key={"morph"+index}>
                    <Chip
                      size="sm"
                      variant="flat"
                      color="warning"
                    >
                      {morph.partOfSpeech && (<Chip className="chip-lefty"
                        size="sm"
                        variant="solid"
                        color="warning"
                      >
                        {morph.partOfSpeech}
                      </Chip>)}

                      {morphologyAsString(morph)}
                    </Chip>
                  </div>)
                })}
                <Divider className="my-3"></Divider>
                <div>
                  <Tabs aria-label="Dynamic tabs" items={tabs} color="secondary">
                    {(item) => (
                      <Tab key={item.id} title={item.label}>
                        <div key="lemmaword" className="lemma-word" onClick={() => openWordStudy(item)}>
                          <div className="the-lemma">
                            {getLemma(item)}
                          </div>
                          <div className="the-strong">
                          <Chip className="chip-lefty"
                            size="sm"
                            variant="solid"
                          >
                            {getStrongNum(item)}
                          </Chip>
                          </div>
                        </div>
                        { renderTab(item) }
                      </Tab>
                    )}
                  </Tabs>
                  <Divider/>
                  <h1 className="leading-10 uppercase font-bold text-slate-500">Word Appearances</h1>
                  <div className="verses" key="verses">
                    {verses.map((verse, i) => (
                        <div key={"wordverse"+i}>
                            <div className="flex justify-between">
                         <Chip size="sm" variant="flat" color="secondary" radius="md">
                            { Books[verse.entry.bookNumber]?.name }
                            &nbsp;
                            { verse.entry.chapterNumber }:{verse.entry.verseNumber}
                         </Chip>
                         <Button size="sm" variant="flat" color="secondary" onClick={() => gotoVerse(verse.entry.bookNumber, verse.entry.chapterNumber, verse.entry.verseNumber)}>
                            GO
                         </Button>
                            </div>
                         <div key={`verse-${i}`} className="reader">
                            {verse.verse.map((word: Word, j: number) => (
                                <span key={`word-${i}-${j}`} className={(word.id === verse.entry.id) ? "highlight" : ""}>
                                    {word.hebrewWord}
                                </span>
                            ))};
                         </div>
                        </div>
                    ))}
                  </div>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BibleWordStudyComponent;
