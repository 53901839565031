import React, { createContext, ReactNode, useReducer } from 'react';
import { isWord, Word } from '../BibleDatabase/BibleDB';

export const BibleIndicatorContext = createContext<BibleIndicator | null>(null);
export const BibleIndicatorDispatchContext = createContext<React.Dispatch<BibleIndicatorAction> | null>(null);

interface Props {
  children: ReactNode;
}

export enum BibleIndicatorActionType {
  NEXT_CHAPTER,
  PREV_CHAPTER,
  SET_CHAPTER,
  SET_BOOK,
  SET_VERSE,
  SET_WORD,
  SET_FULL_VERSE,
  UNSET_WORD,
  SET_WORDSTUDY,
  UNSET_WORDSTUDY,
  REMOVE_HIGHLIGHT,
}

export interface BibleIndicator {
  book: number;
  chapter: number;
  verse: number;
  activeWord?: Word | null;
  wordStudy?: Word | null;
  highlight?: boolean;
}

export interface BibleIndicatorAction {
  type: number;
  value?: number;
  book?: number;
  chapter?: number;
  verse?: number;
  word?: Word;
  highlight?: boolean;
}

const defaultState: BibleIndicator = {
  book: 1,
  chapter: 1,
  verse: 1,
  activeWord: null,
  wordStudy: null,
  highlight: false,
};

export const BibleIndicatorProvider: React.FC<Props> = ({ children }) => {
  const [bibleIndicators, dispatch] = useReducer(
    bibleIndicatorReducer,
    getDefaultState()
  );

  return (
    <BibleIndicatorContext.Provider value={bibleIndicators}>
      <BibleIndicatorDispatchContext.Provider value={dispatch}>
        {children}
      </BibleIndicatorDispatchContext.Provider>
    </BibleIndicatorContext.Provider>
  );
};

const getDefaultState = () => {
  const state = localStorage.getItem("bibleIndicatorState");
  return state ? JSON.parse(state) : defaultState;
}

const storeAndReturn = (state: BibleIndicator) => {
  localStorage.setItem("bibleIndicatorState", JSON.stringify(state));
  return state;
}

export const bibleIndicatorReducer = (state: BibleIndicator, action: BibleIndicatorAction): BibleIndicator => {
  switch (action.type) {
    case BibleIndicatorActionType.NEXT_CHAPTER:
      return storeAndReturn({...state, chapter: state.chapter+1, verse: 1});
    case BibleIndicatorActionType.PREV_CHAPTER:
      return storeAndReturn({...state, chapter: state.chapter-1, verse: 1}); 
    case BibleIndicatorActionType.SET_CHAPTER:
      if (!action.value) return state;
      return storeAndReturn({...state, chapter: action.value});
    case BibleIndicatorActionType.SET_BOOK:
      if (!action.value) return state;
      return storeAndReturn({...state, book: action.value});
    case BibleIndicatorActionType.SET_VERSE:
      if (!action.value) return state;
      return storeAndReturn({...state, verse: action.value});
    case BibleIndicatorActionType.SET_WORD:
      if (!isWord(action.word)) return state;
      return storeAndReturn({...state, activeWord: action.word, highlight: false});
    case BibleIndicatorActionType.UNSET_WORD:
      return storeAndReturn({...state, activeWord: null });
    case BibleIndicatorActionType.SET_WORDSTUDY:
      return {...state, wordStudy: action.word, highlight: false };
    case BibleIndicatorActionType.UNSET_WORDSTUDY:
      return {...state, wordStudy: null }
    case BibleIndicatorActionType.SET_FULL_VERSE:
      return storeAndReturn({...state, book: action.book ?? 1, chapter: action.chapter ?? 1, verse: action.verse ?? 1, highlight: action.highlight ?? true})
    case BibleIndicatorActionType.REMOVE_HIGHLIGHT:
      return {...state, highlight: false}
    default:
      return storeAndReturn(state);
  }
};
