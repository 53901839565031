
import { Popover as NativePopover, PopoverProps } from "@nextui-org/popover";
import { useState } from "react";

export function Popover(props:PopoverProps) {
    const [visible, setVisible] = useState(false);
    return (
        <NativePopover
            {...props}
            isOpen={visible}
            onOpenChange={(isOpen: boolean) => setVisible(isOpen)}
            shouldCloseOnInteractOutside={() => {
                setVisible(false);
                return false;
            }}
        >
        </NativePopover>
    );
}