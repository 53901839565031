import {
    Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/modal";

import {
  BibleIndicatorActionType,
  BibleIndicatorContext,
  BibleIndicatorDispatchContext,
} from "../state/BibleIndicator.context";
import { useContext, useEffect, useRef, useState } from "react";
import { Morphology, morphologyAsString, parseMorphology } from "../utilities/MorphologyParser";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../BibleDatabase/BibleDB";
import { Tab, Tabs } from "@nextui-org/tabs";
import { Chip } from "@nextui-org/chip";
import { Books } from "../BibleDatabase/BibleTypes";
import { Divider } from "@nextui-org/divider";
import { ScrollShadow } from "@nextui-org/scroll-shadow";

const BibleWordDetailComponent = () => {
  const stateBible = useContext(BibleIndicatorContext);
  const dispatchBible = useContext(BibleIndicatorDispatchContext);

  const [queryId, setQueryId] = useState<number>(0); // State variable for key prop
  const [tabs, setTabs] = useState<any[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const morphology = useRef<Morphology[]>([]);
  
  let queryTabs:any[] = [];

  useLiveQuery(() => {
    const strongs = stateBible?.activeWord?.strongsNumber;
    if (!strongs) return;

    queryTabs = [];

    db.strongs
      .where({ id: strongs })
      .sortBy("id")
      .then(strongsDetails);
    db.bdb
      .where({ id: strongs })
      .sortBy("id")
      .then(bdbDetails);
    
  }, [queryId]);

  useEffect(() => {
    setQueryId(id => id + 1);
    if (stateBible?.activeWord) {
        morphology.current = parseMorphology(stateBible.activeWord.morphology, stateBible.activeWord.hebrewWord);
        onOpen();
    }
    else onClose();
  }, [stateBible?.activeWord, onOpen, onClose]);

  if (!stateBible || !dispatchBible) return null;

  const strongsDetails = (details: any) => {
    console.log(details);
    if (!details || !details.length) return;
    queryTabs = [
      ...queryTabs,
      {id: "strongs", label: "Strongs", content: details}
    ];
    setTabs(queryTabs);
  };

  const bdbDetails = (details: any) => {
    console.log(details)
    if (!details || !details.length) return;
    queryTabs = [
      ...queryTabs,
      {id: "bdb", label: "BDB", content: details}
    ];
    setTabs(queryTabs);
  };

  const getLemma = (item:any) => {
    if (item.content?.length) {
      return item.content[0].lemma || item.content[0].hebrew
    }
  };

  const getStrongNum = (item:any) => {
    if (item.content?.length) {
      return item.content[0].id;
    }
  };

  const renderTab = (item:any) => {
    const {id, content} = item;
    if (id === "bdb") {
      return (
        <>
          <ScrollShadow className="h-[200px]" hideScrollBar>
          {content.map((item:any, index:number) => (
          <div key={`bdb${index}`}>
            <h2 className="defs">{item.definition}</h2>
            <ul className="defs">
              {item.secondary.map((secondary:string, i:number) => (
                <li key={i} dangerouslySetInnerHTML={{ __html: secondary }}></li>
              ))}
            </ul>
          </div>
          ))}
          </ScrollShadow>
        </>
      )
    }
    else if (id === "strongs") {
      return (
        <>
          <ScrollShadow className="h-[200px]" hideScrollBar>
          {content.map((item:any, index:number) => (
          <div key={`strongs${index}`}>
            <ul className="defs">
              <li key="def">{item.definition}</li>
              <li key="kjvdef">{item.kjv_definition}</li>
              <li key="deriv">{item.derivation}</li>
            </ul>
          </div>
          ))}
          </ScrollShadow>
        </>
      )
    }
  }

  const openWordStudy = (word: any) => {
    console.log(word, stateBible?.activeWord);
    if (stateBible?.activeWord) {
      dispatchBible({type: BibleIndicatorActionType.SET_WORDSTUDY, word: stateBible.activeWord});
    }
  };

  const bookNumber = stateBible?.activeWord?.bookNumber || -1;
  const book = Books[bookNumber]?.name;
  const chapter = stateBible?.activeWord?.chapterNumber;
  const verse = stateBible?.activeWord?.verseNumber;

  return (
    <Modal
      className="dark text-foreground bg-background"
      placement="bottom"
      backdrop="blur"
      size="lg"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        dispatchBible?.({type: BibleIndicatorActionType.UNSET_WORD});
      }}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {book && (<div className="flex">
                <Chip
                  variant="flat"
                  color="secondary"
                >
                  {book} {chapter}:{verse}
                </Chip>
              </div>)}

              <h1 className="heb-word">{stateBible?.activeWord?.hebrewWord}</h1>

            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col">
                {morphology?.current?.map((morph: Morphology, index: number) => {
                  return (<div className="chips" key={"morph"+index}>
                    <Chip
                      size="sm"
                      variant="flat"
                      color="warning"
                    >
                      {morph.partOfSpeech && (<Chip className="chip-lefty"
                        size="sm"
                        variant="solid"
                        color="warning"
                      >
                        {morph.partOfSpeech}
                      </Chip>)}

                      {morphologyAsString(morph)}
                    </Chip>
                  </div>)
                })}
                <Divider className="my-3"></Divider>
                <div>
                  <Tabs aria-label="Dynamic tabs" items={tabs} color="secondary">
                    {(item) => (
                      <Tab key={item.id} title={item.label}>
                        <div key="lemmaword" className="lemma-word" onClick={() => openWordStudy(item)}>
                          <div className="the-lemma">
                            {getLemma(item)}
                          </div>
                          <div className="the-strong">
                          <Chip className="chip-lefty"
                            size="sm"
                            variant="solid"
                          >
                            {getStrongNum(item)}
                          </Chip>
                          </div>
                        </div>
                        { renderTab(item) }
                      </Tab>
                    )}
                  </Tabs>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BibleWordDetailComponent;
