import { useEffect } from 'react';
import axios from 'axios';
import { Word, db } from '../BibleDatabase/BibleDB';

interface BibleLoaderProps {
  setIsLoaded: (loaded: boolean) => void;
}

const BibleLoaderComponent = (props:BibleLoaderProps) => {

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const fetchBibleData = async () => {
      try {
        // Check if there is any data in the Dexie table
        const count = await db.bible.count();

        // If no data, load it from bible.json file
        if (count === 0) {
          const response = await axios.get('/bible.json', {
            cancelToken: cancelTokenSource.token,
          }); // Assuming bible.json is in the public directory
          const bibleData = response.data;
          const words = [];

          for (const verse of bibleData) {
            for (const word of verse) {
              const wordObject = arrayToWordObject(word);
              words.push(wordObject);
            }
          }

          // Insert data into the Dexie table
          await db.bible.bulkAdd(words);
        }
        return true;
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error);
        }  
        return false;
      }
    };

    const arrayToWordObject = (word: any[]) : Word => {
      return {
          bookNumber: word[0],
          chapterNumber: word[1],
          verseNumber: word[2],
          hebrewWord: word[3],
          strongsNumber: word[4],
          morphology: word[5],
          transliteration: word[6],
          translation: word[7]
      };
    };

    const fetchBDBData = async() => {
      try {
        // Check if there is any data in the Dexie table
        const count = await db.bdb.count();

        // If no data, load it from bdb.json file
        if (count === 0) {
          const response = await axios.get('/definitions.json', {
            cancelToken: cancelTokenSource.token,
          }); // Assuming definitions.json is in the public directory
          const bdbData = response.data;
          const defs: any[] = [];

          for (let definitionKey in bdbData) {
            defs.push({
              id: definitionKey,
              strongNumber: definitionKey,
              hebrew: bdbData[definitionKey].h,
              definition: bdbData[definitionKey].d,
              secondary: bdbData[definitionKey].s,
            });
          }

          // Insert data into the Dexie table
          await db.bdb.bulkAdd(defs);
        }
        return true;
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error);
        }  
        return false;
      }
    }

    const fetchStrongsData = async() => {
      try {
        // Check if there is any data in the Dexie table
        const count = await db.strongs.count();

        // If no data, load it from file
        if (count === 0) {
          const response = await axios.get('/strongs-hebrew-dictionary.json', {
            cancelToken: cancelTokenSource.token,
          }); // Assuming is in the public directory
          const strongsData = response.data;
          const defs: any[] = [];

          for (let definitionKey in strongsData) {
            const strongs = strongsData[definitionKey];
            defs.push({
              id: definitionKey,
              strongNumber: definitionKey,
              lemma: strongs.lemma,
              transliteration: strongs.xlit,
              pronounciation: strongs.pron,
              derivation: strongs.derivation,
              definition: strongs.strongs_def,
              kjv_definition: strongs.kjv_def,
            });
          }

          // Insert data into the Dexie table
          await db.strongs.bulkAdd(defs);
        }
        return true;
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error);
        }
        return false;
      }
    }

    const fetchAll = async() => {
      const bibleDataFetched = await fetchBibleData();
      const bdbDataFetched = await fetchBDBData();
      const strongsDataFetched = await fetchStrongsData();
      if (bibleDataFetched && bdbDataFetched && strongsDataFetched) {
        props.setIsLoaded(true);
      }
    };

    fetchAll();

    return () => {
      cancelTokenSource.cancel("Request cancelled");
    };
  }, []);

  return null;
};

export default BibleLoaderComponent;