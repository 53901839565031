import "./App.css";
import BibleLoaderComponent from "./components/BibleLoader.component";
import BibleSelectorComponent from "./components/BibleSelector.component";
import { BibleIndicatorProvider } from "./state/BibleIndicator.context";
import BibleReaderComponent from "./components/BibleReader.component";
import { useState } from "react";
import BibleWordDetailComponent from "./components/BibleWordDetail";
import BibleWordStudyComponent from "./components/BibleWordStudy";

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
    {!isLoaded && <BibleLoaderComponent setIsLoaded={setIsLoaded} />}
    {isLoaded && (
      <BibleIndicatorProvider>
        <div className="App">
          <BibleSelectorComponent />
          <BibleReaderComponent />
          <BibleWordDetailComponent />
          <BibleWordStudyComponent />
        </div>
      </BibleIndicatorProvider>
      )}
    </>
  );
}

export default App;
