import { PopoverContent, PopoverTrigger } from "@nextui-org/popover";
import { Word } from "../BibleDatabase/BibleDB";
import { Divider } from "@nextui-org/divider";
import { Button } from "@nextui-org/button";
import {
  BibleIndicatorActionType,
  BibleIndicatorDispatchContext,
} from "../state/BibleIndicator.context";
import { useContext } from "react";
import { Popover } from "./PopoverPlus";

interface BibleWordProps {
  word: Word;
}

function BibleWordComponent(props: BibleWordProps) {

  const dispatchBible = useContext(BibleIndicatorDispatchContext);
  if (!dispatchBible) return null;

  const selectWord = () => {
    dispatchBible({
      type: BibleIndicatorActionType.SET_WORD,
      word: props.word,
    });
  };

  return (
    <Popover
      key={props.word.id}
      placement="top"
      color="secondary"
      shouldCloseOnBlur={true}
      showArrow={false}
    >
      <PopoverTrigger>
        <span className={`v${props.word.verseNumber}`}>{props.word.hebrewWord}</span>
      </PopoverTrigger>
      <PopoverContent>
        <div className="flex px-1 py-2">
          <div>
            <div className="text-small font-bold">{props.word.translation}</div>
            <div className="text-tiny">{props.word.transliteration}</div>
          </div>
          <Divider orientation="vertical" className="mx-1"></Divider>
          <div>
            <Button color="secondary" variant="shadow" onClick={selectWord} isIconOnly={true}>
                <img src="/icons/bottom_panel_open.svg" alt="open panel"/>
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default BibleWordComponent;
